import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    EditOutlined,
    ExportOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    HolderOutlined,
    PlusOutlined
} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { TableColumnsType } from 'antd';
import {Button, Space, Table, Tooltip} from 'antd';
import { imgUrl } from 'helpers/imgUrl';
import _ from 'lodash';

import {onlyCashRuShowcases, onlyCashRuShowcasesVariables} from '../../../graphql/__generated__/onlyCashRuShowcases';
import {
    updateOnlyCashRuShowcase,
    updateOnlyCashRuShowcaseVariables
} from '../../../graphql/__generated__/updateOnlyCashRuShowcase';
import {MUTATION_UPDATE_OK_RU_OFFER, QUERY_ONLYCASH_RU_SHOWCASES} from '../../../graphql/onlycash-ru';
import {toOnlyCashRuShowcaseEdit} from '../../../routes/routes';

import cls from './Showcase.module.scss'

interface DataType {
    key: string;
    position: number;
    logo: string;
    headline: string;
    percent: string;
    term: string;
    age: string;
    URL: string;
    enabled: boolean;
}

interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined />}
            style={{ cursor: 'move' }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const Row: React.FC<RowProps> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: props['data-row-key'] });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    const contextValue = useMemo<RowContextProps>(
        () => ({ setActivatorNodeRef, listeners }),
        [setActivatorNodeRef, listeners],
    );

    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

export const Showcase = () => {

    const [updateOffer, updateOfferData] = useMutation<updateOnlyCashRuShowcase,updateOnlyCashRuShowcaseVariables>(MUTATION_UPDATE_OK_RU_OFFER)

    const query = useQuery<onlyCashRuShowcases, onlyCashRuShowcasesVariables>(QUERY_ONLYCASH_RU_SHOWCASES, {
        variables: {
            filters: {
            },
            sort: ['position:ASC'],
            pagination: {
                limit: 10000
            }
        },
    })

    const dataSource:DataType[] = _.map(query.data?.onlyCashRuShowcases?.data, (offer) => (
        {
            key: offer.id!,
            position: offer.attributes?.position!,
            logo: imgUrl(offer.attributes?.logo.data?.attributes?.url!),
            headline: offer.attributes?.headline!,
            percent: offer.attributes?.percent!,
            age: offer.attributes?.age!,
            term: offer.attributes?.term!,
            URL: offer.attributes?.URL!,
            enabled: offer.attributes?.enabled!,
        }
    ))

    const onDragEnd = async ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {

            const id = active.id
            const position = dataSource.find((record) => record.key === over?.id)?.position

            await updateOffer({
                variables: {
                    id: id?.toString()!,
                    data: {position: position},
                },
                onCompleted: () => {query.refetch()}
            })
        }
    };

    const navigate = useNavigate();

    const columns:TableColumnsType<DataType> = [
        { key: 'sort', align: 'center', render: () => <DragHandle /> },
        {
            title: '№',
            dataIndex: 'position',
            key: 'position',
            render: (item: any, record: any) => (
                record.enabled ? item : <span className={cls.hided}>{item}</span>
            ),
        },
        {
            title: 'Логотип',
            dataIndex: 'logo',
            key: 'logo',
            render: (img: string, record: any) => (<img src={img} alt={'Logo'} width={140} className={!record.enabled ? cls.hided : undefined}/>)
        },
        {
            title: 'Заголовок',
            dataIndex: 'headline',
            key: 'headline',
            className: 'bold',
            render: (item: any, record: any) => (
                record.enabled ? item : <span className={cls.hided}>{item}</span>
            ),
        },
        {
            title: 'Ставка',
            dataIndex: 'percent',
            key: 'percent',
            render: (item: any, record: any) => (
                record.enabled ? item : <span className={cls.hided}>{item}</span>
            ),
        },
        {
            title: 'Срок',
            dataIndex: 'term',
            key: 'term',
            render: (item: any, record: any) => (
                record.enabled ? item : <span className={cls.hided}>{item}</span>
            ),
        },
        {
            title: 'Возраст',
            dataIndex: 'age',
            key: 'age',
            render: (item: any, record: any) => (
                record.enabled ? item : <span className={cls.hided}>{item}</span>
            ),
        },
        {
            title: 'URL',
            dataIndex: 'URL',
            key: 'URL',
            render: (item: any, record: any) => (
                record.enabled ? item : <span className={cls.hided}>{item}</span>
            ),
        },
        {
            key: 'control',
            render: (_:any, record:any) => (
                <Space>
                    <Tooltip title={'Редактировать'}>
                        <Button type={'link'} onClick={() => navigate(toOnlyCashRuShowcaseEdit(record.key))}
                                icon={<EditOutlined/>}/>
                    </Tooltip>
                    {record.enabled ?
                        <Tooltip title={'Скрыть'}>
                            <Button type={'link'} onClick={() => updateOfferEnabled(record.key, false)}
                                    icon={<EyeOutlined/>}/>
                        </Tooltip>
                        :
                        <Tooltip title={'Показать'}>
                            <Button type={'link'} onClick={() => updateOfferEnabled(record.key, true)}
                                    icon={<EyeInvisibleOutlined/>}/>
                        </Tooltip>
                    }
                </Space>
            )
        },
    ];

    const updateOfferEnabled = async (id: string, enabled: boolean) => {
        await updateOffer({
            variables: {id: id, data: {enabled: enabled}},
            onCompleted: () => {
                query.refetch()
            }
        })
    }

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Button icon={<PlusOutlined/>} onClick={() =>  navigate(toOnlyCashRuShowcaseEdit('new'))}>Добавить</Button>

                </div>
                <div className={cls.right}>
                    <a href={'https://onlycash.tech/offers/SDR8KzArwCTW9Vy6Q7excL'} target={'_blank'} rel={'noreferrer'}>
                        <Button icon={<ExportOutlined/>}>Посмотреть</Button>
                    </a>
                </div>
            </div>

            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext items={dataSource.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                    <Table<DataType>
                        rowKey="key"
                        components={{ body: { row: Row } }}
                        columns={columns}
                        dataSource={dataSource}
                        loading={query.loading || updateOfferData.loading}
                    />
                </SortableContext>
            </DndContext>

            {/*<Table dataSource={dataSource1} columns={columns1} loading={query.loading} />*/}
           
        </>

    )

}