import {useMutation, useQuery} from '@apollo/client';
import {Button, Space, Table} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import {getSFTPFiles} from '../../graphql/__generated__/getSFTPFiles';
import {getSFTPStatus} from '../../graphql/__generated__/getSFTPStatus';
import {runSFTPCommand} from '../../graphql/__generated__/runSFTPCommand';
import {QUERY_SFTP_FILES, QUERY_SFTP_STATUS, RUN_SFTP_COMMAND} from '../../graphql/sftp';
import formatBytes from '../../helpers/formatBytes';
import {csvUrl} from '../../helpers/imgUrl';

import cls from './SFTP.module.scss'

export const SFTPSiab = () => {

    const queryStatus = useQuery<getSFTPStatus>(QUERY_SFTP_STATUS)
    const queryFiles = useQuery<getSFTPFiles>(QUERY_SFTP_FILES)
    const [runCommand] = useMutation<runSFTPCommand>(RUN_SFTP_COMMAND)

    const dataSource = _.map(queryFiles.data?.getSFTPFiles, (item, index) => ({
        ...item,
        date: dayjs(item?.date).format('DD.MM.YYYY'),
        size: formatBytes(item?.size),
        key: index,
    }))

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Файл',
            dataIndex: 'name',
            key: 'name',
            render: (name:string) => (<a href={csvUrl(name)} target={'_blank'} rel={'noreferrer'}>{name}</a>)
        },
        {
            title: 'Размер',
            dataIndex: 'size',
            key: 'size',
        },
    ];

    const run = async () => {
        await runCommand({
            onCompleted: (data) => {
                if (data.runSFTPCommand?.done) {
                    queryStatus.refetch()
                    queryFiles.refetch()
                }
            }
        })
    }
    return (
        <>

            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <Button type={'primary'} onClick={run}>Проверить файлы</Button>
                        <span>
                            Файлов в обработке: {queryStatus.data?.getSFTPStatus?.count}
                        </span>
                    </Space>
                </div>
                <div className={cls.right}>
                </div>
            </div>

            <Table dataSource={dataSource} columns={columns} loading={queryFiles.loading}/>

        </>
    )
}