import { useParams } from 'react-router-dom';

import {OfferEdit} from './OfferEdit';
import {OfferNew} from './OfferNew';

export const Offer = () => {
    const {id} = useParams()
    return (
        <div className={'form'}>
            {id === 'new' ?
                <OfferNew/>
                :
                <OfferEdit id={id!}/>
            }
        </div>
    )
}