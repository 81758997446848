/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_CONSULTATION_STATUS {
  DONE = "DONE",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  NEW = "NEW",
}

export enum ENUM_DELIVERYRECORD_EMAILSTATUS {
  AWAIT_1 = "AWAIT_1",
  CREATED_2 = "CREATED_2",
  ERROR_4 = "ERROR_4",
  SENDED_3 = "SENDED_3",
}

export enum ENUM_DELIVERYRECORD_PRODUCT {
  my_house_light = "my_house_light",
  ns_world = "ns_world",
  nspd_world = "nspd_world",
}

export enum ENUM_DELIVERYRECORD_SENDSTATUS {
  CREATED_1 = "CREATED_1",
  ERROR_3 = "ERROR_3",
  SENDED_2 = "SENDED_2",
}

export enum ENUM_ONLYCASHKZLEAD_STATUS {
  approved = "approved",
  approved_sent = "approved_sent",
  pending = "pending",
  pending_sent = "pending_sent",
  rejected = "rejected",
  rejected_sent = "rejected_sent",
}

export enum ENUM_ONLYCASHKZ_STATUS {
  CREATED_1 = "CREATED_1",
  ERROR_4 = "ERROR_4",
  SENDED_2 = "SENDED_2",
  SUCCESS_3 = "SUCCESS_3",
}

export enum ENUM_ONLYCASHRU_STATUS {
  CREATED_1 = "CREATED_1",
  ERROR_4 = "ERROR_4",
  SENDED_2 = "SENDED_2",
  SUCCESS_3 = "SUCCESS_3",
}

export enum ENUM_PARTNER_STATUS {
  DONE = "DONE",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  NEW = "NEW",
}

export enum ENUM_REFUNDREQUEST_ORDERSTATUS {
  DONE = "DONE",
  ERROR = "ERROR",
  NEW = "NEW",
  WAITING = "WAITING",
}

export enum ENUM_REFUNDREQUEST_STATUS {
  GEN_ERROR_7 = "GEN_ERROR_7",
  IN_GEN_QUEUE_1 = "IN_GEN_QUEUE_1",
  READY_TO_SEND_2 = "READY_TO_SEND_2",
  RECEIVE_SIGN_4 = "RECEIVE_SIGN_4",
  REFUND_DONE_5 = "REFUND_DONE_5",
  REJECT_6 = "REJECT_6",
  SENDED_TO_CLIENT_3 = "SENDED_TO_CLIENT_3",
}

export enum ENUM_REFUNDREQUEST_TEMPLATE {
  CERT_NUMBER_ERROR = "CERT_NUMBER_ERROR",
  DEFAULT_REQUEST = "DEFAULT_REQUEST",
  NON_RETURN = "NON_RETURN",
  ON_PERSONAL = "ON_PERSONAL",
  PURCHASE_PLACE = "PURCHASE_PLACE",
  SEVEN_DAYS = "SEVEN_DAYS",
  WALK_TO_MAIL = "WALK_TO_MAIL",
  WALK_TO_MFO = "WALK_TO_MFO",
  WALK_TO_OFFICE = "WALK_TO_OFFICE",
}

export enum ENUM_REFUNDREQUEST_TYPE {
  free = "free",
  vip = "vip",
}

export enum ENUM_SMSINFO_SENDSTATUS {
  CREATED_1 = "CREATED_1",
  ERROR_3 = "ERROR_3",
  SENDED_2 = "SENDED_2",
}

export enum ENUM_SMSSCHEDULE_STATUS {
  CREATED_1 = "CREATED_1",
  ERROR_3 = "ERROR_3",
  SENDED_2 = "SENDED_2",
}

export interface ActivateFiltersInput {
  id?: IDFilterInput | null;
  Certificate_Number?: StringFilterInput | null;
  Certificate_Date?: DateFilterInput | null;
  Device_Type?: StringFilterInput | null;
  Device_Name?: StringFilterInput | null;
  Device_Number?: StringFilterInput | null;
  FIO?: StringFilterInput | null;
  Phone?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (ActivateFiltersInput | null)[] | null;
  or?: (ActivateFiltersInput | null)[] | null;
  not?: ActivateFiltersInput | null;
}

export interface BooleanFilterInput {
  and?: (boolean | null)[] | null;
  or?: (boolean | null)[] | null;
  not?: BooleanFilterInput | null;
  eq?: boolean | null;
  eqi?: boolean | null;
  ne?: boolean | null;
  nei?: boolean | null;
  startsWith?: boolean | null;
  endsWith?: boolean | null;
  contains?: boolean | null;
  notContains?: boolean | null;
  containsi?: boolean | null;
  notContainsi?: boolean | null;
  gt?: boolean | null;
  gte?: boolean | null;
  lt?: boolean | null;
  lte?: boolean | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (boolean | null)[] | null;
  notIn?: (boolean | null)[] | null;
  between?: (boolean | null)[] | null;
}

export interface ConsultationFiltersInput {
  id?: IDFilterInput | null;
  Certificate_Number?: StringFilterInput | null;
  Certificate_Date?: DateFilterInput | null;
  FIO?: StringFilterInput | null;
  Phone?: StringFilterInput | null;
  status?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (ConsultationFiltersInput | null)[] | null;
  or?: (ConsultationFiltersInput | null)[] | null;
  not?: ConsultationFiltersInput | null;
}

export interface DateFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: DateFilterInput | null;
  eq?: any | null;
  eqi?: any | null;
  ne?: any | null;
  nei?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface DateTimeFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: DateTimeFilterInput | null;
  eq?: any | null;
  eqi?: any | null;
  ne?: any | null;
  nei?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface DeliveryFileFiltersInput {
  id?: IDFilterInput | null;
  records?: DeliveryRecordFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (DeliveryFileFiltersInput | null)[] | null;
  or?: (DeliveryFileFiltersInput | null)[] | null;
  not?: DeliveryFileFiltersInput | null;
}

export interface DeliveryFileInput {
  media?: string | null;
  records?: (string | null)[] | null;
}

export interface DeliveryRecordFiltersInput {
  id?: IDFilterInput | null;
  FIO?: StringFilterInput | null;
  CertNumber?: StringFilterInput | null;
  CertDate?: DateFilterInput | null;
  InsuranceAmount?: FloatFilterInput | null;
  Cost?: FloatFilterInput | null;
  Passport?: StringFilterInput | null;
  Birthday?: DateFilterInput | null;
  Phone?: StringFilterInput | null;
  Duration?: IntFilterInput | null;
  product?: StringFilterInput | null;
  data?: JSONFilterInput | null;
  sendStatus?: StringFilterInput | null;
  CertFilename?: StringFilterInput | null;
  file?: DeliveryFileFiltersInput | null;
  code?: StringFilterInput | null;
  activated?: BooleanFilterInput | null;
  activatedAt?: DateTimeFilterInput | null;
  email?: StringFilterInput | null;
  emailStatus?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (DeliveryRecordFiltersInput | null)[] | null;
  or?: (DeliveryRecordFiltersInput | null)[] | null;
  not?: DeliveryRecordFiltersInput | null;
}

export interface FileInfoInput {
  name?: string | null;
  alternativeText?: string | null;
  caption?: string | null;
}

export interface FloatFilterInput {
  and?: (number | null)[] | null;
  or?: (number | null)[] | null;
  not?: FloatFilterInput | null;
  eq?: number | null;
  eqi?: number | null;
  ne?: number | null;
  nei?: number | null;
  startsWith?: number | null;
  endsWith?: number | null;
  contains?: number | null;
  notContains?: number | null;
  containsi?: number | null;
  notContainsi?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (number | null)[] | null;
  notIn?: (number | null)[] | null;
  between?: (number | null)[] | null;
}

export interface IDFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: IDFilterInput | null;
  eq?: string | null;
  eqi?: string | null;
  ne?: string | null;
  nei?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

export interface IntFilterInput {
  and?: (number | null)[] | null;
  or?: (number | null)[] | null;
  not?: IntFilterInput | null;
  eq?: number | null;
  eqi?: number | null;
  ne?: number | null;
  nei?: number | null;
  startsWith?: number | null;
  endsWith?: number | null;
  contains?: number | null;
  notContains?: number | null;
  containsi?: number | null;
  notContainsi?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (number | null)[] | null;
  notIn?: (number | null)[] | null;
  between?: (number | null)[] | null;
}

export interface JSONFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: JSONFilterInput | null;
  eq?: any | null;
  eqi?: any | null;
  ne?: any | null;
  nei?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface LossFiltersInput {
  id?: IDFilterInput | null;
  FIO?: StringFilterInput | null;
  Phone?: StringFilterInput | null;
  Certificate_Number?: StringFilterInput | null;
  Certificate_Date?: DateFilterInput | null;
  Comment?: StringFilterInput | null;
  Email?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (LossFiltersInput | null)[] | null;
  or?: (LossFiltersInput | null)[] | null;
  not?: LossFiltersInput | null;
}

export interface OnlyCashKzFiltersInput {
  id?: IDFilterInput | null;
  FIO?: StringFilterInput | null;
  Phone?: StringFilterInput | null;
  Code?: StringFilterInput | null;
  status?: StringFilterInput | null;
  lead?: OnlyCashKzLeadFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (OnlyCashKzFiltersInput | null)[] | null;
  or?: (OnlyCashKzFiltersInput | null)[] | null;
  not?: OnlyCashKzFiltersInput | null;
}

export interface OnlyCashKzLeadFiltersInput {
  id?: IDFilterInput | null;
  click_id?: StringFilterInput | null;
  sendCodeAt?: DateTimeFilterInput | null;
  checkCodeAt?: DateTimeFilterInput | null;
  uniqPhone?: BooleanFilterInput | null;
  inRandom?: BooleanFilterInput | null;
  status?: StringFilterInput | null;
  logs?: OnlyCashKzLogFiltersInput | null;
  UID?: StringFilterInput | null;
  reg?: OnlyCashKzFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (OnlyCashKzLeadFiltersInput | null)[] | null;
  or?: (OnlyCashKzLeadFiltersInput | null)[] | null;
  not?: OnlyCashKzLeadFiltersInput | null;
}

export interface OnlyCashKzLogFiltersInput {
  id?: IDFilterInput | null;
  URL?: StringFilterInput | null;
  response?: StringFilterInput | null;
  responseJSON?: JSONFilterInput | null;
  lead?: OnlyCashKzLeadFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (OnlyCashKzLogFiltersInput | null)[] | null;
  or?: (OnlyCashKzLogFiltersInput | null)[] | null;
  not?: OnlyCashKzLogFiltersInput | null;
}

export interface OnlyCashRuFiltersInput {
  id?: IDFilterInput | null;
  FIO?: StringFilterInput | null;
  Phone?: StringFilterInput | null;
  Code?: StringFilterInput | null;
  status?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (OnlyCashRuFiltersInput | null)[] | null;
  or?: (OnlyCashRuFiltersInput | null)[] | null;
  not?: OnlyCashRuFiltersInput | null;
}

export interface OnlyCashRuShowcaseFiltersInput {
  id?: IDFilterInput | null;
  headline?: StringFilterInput | null;
  percent?: StringFilterInput | null;
  term?: StringFilterInput | null;
  age?: StringFilterInput | null;
  URL?: StringFilterInput | null;
  enabled?: BooleanFilterInput | null;
  position?: IntFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (OnlyCashRuShowcaseFiltersInput | null)[] | null;
  or?: (OnlyCashRuShowcaseFiltersInput | null)[] | null;
  not?: OnlyCashRuShowcaseFiltersInput | null;
}

export interface OnlyCashRuShowcaseInput {
  headline?: string | null;
  logo?: string | null;
  percent?: string | null;
  term?: string | null;
  age?: string | null;
  URL?: string | null;
  enabled?: boolean | null;
  position?: number | null;
}

export interface PaginationArg {
  page?: number | null;
  pageSize?: number | null;
  start?: number | null;
  limit?: number | null;
}

export interface PartnerFiltersInput {
  id?: IDFilterInput | null;
  FIO?: StringFilterInput | null;
  Company?: StringFilterInput | null;
  Phone?: StringFilterInput | null;
  status?: StringFilterInput | null;
  INN?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (PartnerFiltersInput | null)[] | null;
  or?: (PartnerFiltersInput | null)[] | null;
  not?: PartnerFiltersInput | null;
}

export interface RefundRequestFiltersInput {
  id?: IDFilterInput | null;
  status?: StringFilterInput | null;
  requestPdfFileUrl?: StringFilterInput | null;
  requestData?: JSONFilterInput | null;
  validationErrorMessage?: StringFilterInput | null;
  template?: StringFilterInput | null;
  type?: StringFilterInput | null;
  orderNumber?: StringFilterInput | null;
  orderStatus?: StringFilterInput | null;
  orderLink?: StringFilterInput | null;
  FIO?: StringFilterInput | null;
  Phone?: StringFilterInput | null;
  Email?: StringFilterInput | null;
  orderValue?: IntFilterInput | null;
  application?: BooleanFilterInput | null;
  details?: BooleanFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (RefundRequestFiltersInput | null)[] | null;
  or?: (RefundRequestFiltersInput | null)[] | null;
  not?: RefundRequestFiltersInput | null;
}

export interface SmsFileFiltersInput {
  id?: IDFilterInput | null;
  sms_infos?: SmsInfoFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (SmsFileFiltersInput | null)[] | null;
  or?: (SmsFileFiltersInput | null)[] | null;
  not?: SmsFileFiltersInput | null;
}

export interface SmsFileInput {
  media?: string | null;
  sms_infos?: (string | null)[] | null;
}

export interface SmsInfoFiltersInput {
  id?: IDFilterInput | null;
  FIO?: StringFilterInput | null;
  UID?: StringFilterInput | null;
  phone?: StringFilterInput | null;
  date?: DateFilterInput | null;
  duration?: IntFilterInput | null;
  rowData?: JSONFilterInput | null;
  sendStatus?: StringFilterInput | null;
  activated?: BooleanFilterInput | null;
  activatedAt?: DateTimeFilterInput | null;
  sms_file?: SmsFileFiltersInput | null;
  code?: StringFilterInput | null;
  sending?: BooleanFilterInput | null;
  sms_schedules?: SmsScheduleFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (SmsInfoFiltersInput | null)[] | null;
  or?: (SmsInfoFiltersInput | null)[] | null;
  not?: SmsInfoFiltersInput | null;
}

export interface SmsInfoInput {
  FIO?: string | null;
  UID?: string | null;
  phone?: string | null;
  date?: any | null;
  duration?: number | null;
  rowData?: any | null;
  sendStatus?: ENUM_SMSINFO_SENDSTATUS | null;
  activated?: boolean | null;
  activatedAt?: any | null;
  sms_file?: string | null;
  code?: string | null;
  sending?: boolean | null;
  sms_schedules?: (string | null)[] | null;
}

export interface SmsScheduleFiltersInput {
  id?: IDFilterInput | null;
  text?: StringFilterInput | null;
  log?: JSONFilterInput | null;
  sms_info?: SmsInfoFiltersInput | null;
  status?: StringFilterInput | null;
  date?: DateFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (SmsScheduleFiltersInput | null)[] | null;
  or?: (SmsScheduleFiltersInput | null)[] | null;
  not?: SmsScheduleFiltersInput | null;
}

export interface StringFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: StringFilterInput | null;
  eq?: string | null;
  eqi?: string | null;
  ne?: string | null;
  nei?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

export interface UsersPermissionsLoginInput {
  identifier: string;
  password: string;
  provider: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
