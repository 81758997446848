import {gql} from '@apollo/client';
export const QUERY_ONLYCASH_RU = gql`
    query onlyCashRus($filters: OnlyCashRuFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        onlyCashRus(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    FIO
                    Phone
                    Code
                    status
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;

export const QUERY_ONLYCASH_RU_SHOWCASES = gql`
    query onlyCashRuShowcases($filters: OnlyCashRuShowcaseFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        onlyCashRuShowcases(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    headline
                    logo {
                        data {
                            id
                            attributes {
                                url
                            }
                        }
                    }
                    age
                    enabled
                    term
                    URL
                    position
                    percent
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;

export const QUERY_ONLYCASH_RU_SHOWCASE = gql`
    query onlyCashRuShowcase($id: ID!) {
        onlyCashRuShowcase(id: $id) {
            data {
                id
                attributes {
                    headline
                    logo {
                        data {
                            id
                            attributes {
                                url
                            }
                        }
                    }
                    age
                    enabled
                    term
                    URL
                    position
                    percent
                }
            }
        }
    }
`;

export const MUTATION_CREATE_OK_RU_OFFER = gql`
    mutation createOnlyCashRuShowcase($data: OnlyCashRuShowcaseInput!) {
        createOnlyCashRuShowcase(data: $data) {
            data {
                id
            }
        }
    }
`;

export const MUTATION_UPDATE_OK_RU_OFFER = gql`
    mutation updateOnlyCashRuShowcase($id: ID!, $data: OnlyCashRuShowcaseInput!) {
        updateOnlyCashRuShowcase(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;