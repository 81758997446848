import {useNavigate} from 'react-router';
import {useMutation} from '@apollo/client';

import {
    createOnlyCashRuShowcase,
    createOnlyCashRuShowcaseVariables
} from '../../../../graphql/__generated__/createOnlyCashRuShowcase';
import {MUTATION_CREATE_OK_RU_OFFER} from '../../../../graphql/onlycash-ru';

import {IOffer, OfferForm} from './OfferForm';

export const OfferNew = () => {

    const initValues: IOffer = {
        logo: {
            id: '',
            url: '',
        },
        headline: '',
        percent: '',
        term: '',
        age: '',
        URL: '',
    }

    const [createOffer] = useMutation<createOnlyCashRuShowcase, createOnlyCashRuShowcaseVariables>(MUTATION_CREATE_OK_RU_OFFER)
    const navigate = useNavigate()

    const onFinish = async (data: IOffer) => {
        await createOffer({
            variables: {
                data:
                    {
                        ...data,
                        logo: data.logo.id,
                        position: 1,
                    },
            },
            onCompleted: () => {
                navigate(-1)
            },
        })
    }

    return (
    <>
        <h2>Новый оффер</h2>
        <OfferForm values={initValues} action={'Создать'} onFinish={onFinish}/>
    </>
    )

}