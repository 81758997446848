import {gql} from '@apollo/client';
export const QUERY_SFTP_FILES = gql`
    query getSFTPFiles {
        getSFTPFiles {
            date
            size
            name
        }
    }
`;

export const QUERY_SFTP_STATUS = gql`
    query getSFTPStatus {
        getSFTPStatus {
            count
        }
    }
`;

export const RUN_SFTP_COMMAND = gql`
    mutation runSFTPCommand {
        runSFTPCommand {
            done
        }
    }
`;

